export const subjects = [
  { value: 'Arts', label: 'Arts' },
  { value: 'Biology', label: 'Biology' },
  { value: 'Science', label: 'Science' },
  { value: 'Technology', label: 'Technology' },
  { value: 'Physical Education', label: 'Physical Education' },
  { value: 'Physics', label: 'Physics' },
  { value: 'Geography', label: 'Geography' },
  { value: 'History', label: 'History' },
  { value: 'Mathematics', label: 'Mathematics' },
  { value: 'English', label: 'English' },
  { value: 'Chemistry', label: 'Chemistry' },
];
